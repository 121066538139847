<template>
	<div class="products-main">
		<div class="page_banner"><img src="@/assets/images/carbonNeutral-banner.jpg">
			<div class="words">
				<p>
					<span>碳达峰、碳中和、产业链</span>
					软件定制化服务提供商
				</p>
			</div>
		</div>
		<div class="products layout">
			<MenuLayout />
			<div class="products-contact">
				<div class="BreadCrumbs"><span>碳达峰、碳中和、产业链</span>
					<p class="crumbs"><a>您的位置：</a> <a>产品</a>> <a>碳达峰、碳中和、产业链</a>
					</p>
				</div>
				<div class="content">
					<img src="@/assets/images/products/carbonNeutral/01.jpg" alt="中国双碳背景" title="中国双碳背景" />
					<img src="@/assets/images/products/carbonNeutral/02.jpg" alt="区域经济低碳发展遇到的困难"
						title="区域经济低碳发展遇到的困难" />
					<img src="@/assets/images/products/carbonNeutral/03.jpg" alt="项目范围" title="项目范围" />
					<img src="@/assets/images/products/carbonNeutral/04.jpg" alt="多学科统筹管理:适应各种复杂场景的低碳发展方案"
						title="多学科统筹管理:适应各种复杂场景的低碳发展方案" />
					<!-- <img src="@/assets/images/products/carbonNeutral/05.jpg" alt="技术路线--新能源" title="技术路线--新能源" /> -->
					<img src="@/assets/images/products/carbonNeutral/06.jpg" alt="项目优势" title="项目优势" />
					<!-- <img src="@/assets/images/products/carbonNeutral/07.jpg" alt="典型投资3种模式" title="典型投资3种模式" /> -->
					<img src="@/assets/images/products/carbonNeutral/08.jpg" alt="专家顾问团队" title="专家顾问团队" />
					<img src="@/assets/images/products/carbonNeutral/09.jpg" alt="合作机构" title="合作机构" />
					<!-- <img src="@/assets/images/products/carbonNeutral/10.jpg" alt="区域绿色低碳转型战略合作" title="区域绿色低碳转型战略合作" />
					<img src="@/assets/images/products/carbonNeutral/11.jpg" alt="项目推进模式 " title="项目推进模式 " />
					<img src="@/assets/images/products/carbonNeutral/12.jpg" alt="项目绩效" title="项目绩效" />
					<img src="@/assets/images/products/carbonNeutral/13.jpg" alt="武汉市-十二五发展规划及实施-政府智库" title="武汉市-十二五发展规划及实施-政府智库" />
					<img src="@/assets/images/products/carbonNeutral/14.jpg" alt="头部跨国科技企业供应链碳中和项目" title="头部跨国科技企业供应链碳中和项目" />
					<img src="@/assets/images/products/carbonNeutral/15.jpg" alt="新余市-能源与环境监测管理中心项目" title="新余市-能源与环境监测管理中心项目" />
					<img src="@/assets/images/products/carbonNeutral/16.jpg" alt="全球最大亚麻生产企业碳中和项目" title="全球最大亚麻生产企业碳中和项目" />
					<img src="@/assets/images/products/carbonNeutral/17.jpg" alt="全球最大亚麻生产企业碳中和项目" title="全球最大亚麻生产企业碳中和项目" />
					<img src="@/assets/images/products/carbonNeutral/18.jpg" alt="金属加工企业融资租赁" title="金属加工企业融资租赁" />
					<img src="@/assets/images/products/carbonNeutral/19.jpg" alt="成功案例" title="成功案例" />
					<img src="@/assets/images/products/carbonNeutral/20.jpg" alt="电解铝工业企业节能低碳技术应用" altitlet="电解铝工业企业节能低碳技术应用" />
					<img src="@/assets/images/products/carbonNeutral/21.jpg" alt="电解铝工业企业节能低碳技术应用" title="电解铝工业企业节能低碳技术应用" /> -->
				</div>


				<div class="productsChilder">
					<div class="productsChilder-title">
						浙江中政林业开发有限公司简介
					</div>
					<div class="content">
						<p class="indent">
							浙江中政林业开发有限公司坐落于杭州市滨江区亚科中心，公司
							成立于2011年6月，是一家专注于林业碳汇项目开发、碳中和、
							碳资产管理业务的公司。中政林业以卓越的服务水平和稳健务实
							的服务作风赢得了客户的信任以及市场的认可。
						</p>
						<p class="indent">
							公司专注于林业资源的整合、开发、
							低碳技术、碳资产管理、碳中和服务，
							在全国范围内寻求、整合碳汇林资源。
							我们拥有一支专业、高水准的管理和
							技术团队，着重于探索碳汇林的生态
							效益，拓宽林农收益方式，增加政府
							税收，促进林业转型升级并实现效益
							最大化。
						</p>
						<p class="indent">
							2018年，浙江中政林业开发有限公司被评选为浙江省乡村振兴领军企业
							2019 年成为碳交易产业联盟理事单位，
							2019 年开发了湖北省随州市洪山森林经营湖北洪山采伐转保护项目交易金额超3000万元。
							2021 年成为中国节能协会碳中和专业委员会常 务委员单位。
						</p>
			 
					</div>
				</div>
				<div class="productsChilder">
					<h3 class="productsChilder-title">成功案例</h3>
					<div class="content">

						<ul class="caseList">
							<li v-for="item in caseList" :key="item.src">
								<div class="imgbox">
									<img :src="item.src">
								</div>
								<a class="text">
									{{item.text}}
								</a>
							</li>
						</ul>

					</div>
				</div>
			</div>
		</div>

	</div>
</template>
<script>
import MenuLayout from "./components/menu"
export default ({
	data() {
		return {
			caseList: [
				{
					src: require('@/assets/images/case/carbonNeutral/01.jpg'),
					text: "沙雅县林业和草原局"
				},
				{
					src: require('@/assets/images/case/carbonNeutral/02.jpg'),
					text: "新和县"
				}
			]
		}
	},
	components: {
		MenuLayout,
	},
	methods: {

	}
});
</script>
